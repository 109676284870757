import React, {Component} from 'react';
import { createStyles, Theme, withStyles, Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

interface IProps {
  enableButton?: boolean,
  isOpen: boolean,
  title: string,
  msg: any,
  handleClose: () => void,
  classes?: any
}

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      height: "44px",
      backgroundColor: "#222e3e",
      textAlign: "center",
      color: "#ffffff",
      alignItems: "center",
      justifyContent: "center",
      display: "flex"
    },
    dialogContainer: {
      width: "100%",
      backgroundColor: "#d8d8d8"
    },
    labelTop: {
      display: "flex",
      fontSize: "18px",
      color: "#ffffff",
      width: "100%",
      justifyContent: "center"
    },
    content: {
      maxHeight: "90%",
      display: "initial",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: "bold"
    },
    buttonDef: {
      borderRadius: "5px",
      margin: "8px",
      border: "solid 1px #ad8d40",
      boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.75)",
      height: "60%",
      fontSize: "12px",
      width: "163px",
      textTransform: "capitalize",
      backgroundImage: "linear-gradient(to bottom, #ffffff, #f7dea0 9%, #f1c24f)",
      "&:hover": {
        boxShadow: "1px 1px 3px #ccc"
      }
    },
    footerActions: {
      display: "flex",
      justifyContent: "center"
    }
  });

class SimpleModal extends Component<IProps> {
  public render() {
    const {classes, msg, isOpen, handleClose, title, enableButton = true} = this.props;

    return (
      <Dialog classes={{paper: classes.dialogContainer}} open={isOpen} fullWidth={true}>
          <DialogTitle className={classes.dialogTitle}>
          <div className={classes.labelTop}>
            {title}
          </div>
        </DialogTitle>

        <DialogContent className={classes.content}>
          {msg}
        </DialogContent>

        <DialogActions className={classes.footerActions}>
          <Button
            disabled={!enableButton}
            className={classes.buttonDef}
            onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>

      </Dialog>
    );
  }
}

export default withStyles(styles)(SimpleModal);